import React, { useEffect } from 'react';
function script(d, s, id) {
  var js,
    tjs = d.getElementsByTagName(s)[0];
  js = d.createElement(s);
  js.id = id;
  js.src = 'https://app.termly.io/embed-policy.min.js';
  tjs.parentNode.insertBefore(js, tjs);
}

export default function TermsOfService() {
  useEffect(() => {
    script(document, 'script', 'termly-jssdk');
  }, []);
  return (
    <div
      name="termly-embed"
      data-id="1e9c9c1d-5fc3-4f40-b12b-d997ebe02750"
      data-type="iframe"
    />
  );
}
